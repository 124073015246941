import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Layout, Paragraph, Slider } from "~/components";
import { chardhamLinks } from "~/constants/links";
import Testimonials from "~/views/ChardhamHome/Testimonials";

const BlogDetails: React.FC = () => {
  return (
    <Layout
      meta="Explore the history of Chardham and embark on an unforgettable Chardham Yatra by helicopter. Witness the divine beauty and spirituality."
      links={chardhamLinks}
    >
      <div className="mx-auto text-justify px-10">
        <div className="breadcrumb">
          <div className="pt-4">
            <p className="text-sm text-gray-500 font-medium">
              <a href="/">Home</a> {">"} <a href="/blogs">Blogs</a> {"> "}
              History of Chardham: Unleash Chardham Yatra by Helicopter
            </p>
          </div>
        </div>
        <Heading type="h1" className="text-center text-[28px]">
          History of Chardham: Unleash Chardham Yatra by Helicopter
        </Heading>
        <Paragraph>
          The Himalayas, the sacred hills covered in snow, hold within them four
          cherished sites: Yamunotri, Gangotri, Kedarnath, and Badrinath. This
          isn't just a geographic location; it's the soul of the Chardham Yatra,
          a pilgrimage echoing centuries of history and spiritual significance.
          Now, imagine experiencing this sacred space not on foot or road, but
          experiencing{" "}
          <a href="/chardham-by-helicopter">Chardham by Helicopter</a>, your
          heart soaring as the ancient temples unfold beneath you. This dear
          pilgrim, is the magic of{" "}
          <a href="/chardham-by-helicopter">Chardham Helicopter Tour</a> – where
          comfort elevates your spiritual journey to breathtaking heights.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/blog-1-cover-image.png"
            alt="Chardham by Helicopter"
            placeholder="blurred"
            objectFit="contain"
            className="h-[400px]"
          />
          <Paragraph>Chardham by Helicopter</Paragraph>
        </div>
        <Paragraph className="mt-4">
          Before moving on, you can check out{" "}
          <a href="/chardham-by-helicopter">Chardham by Helicopter</a>{" "}
          <a href="/chardham-by-helicopter/packages">packages</a>,{" "}
          <a href="/chardham-by-helicopter/#testimonials">testimonials</a>,{" "}
          <a href="/blogs">blogs</a>,{" "}
          <a href="/chardham-by-helicopter/#photo-gallery">gallery</a>, and{" "}
          <a
            href="https://www.instagram.com/helitaxii/"
            target="_blank"
            rel="noopener noreferrer"
          >
            social media handles
          </a>{" "}
          post reading. Now, enjoy this blog:
        </Paragraph>
        <Heading type="h2" className="mt-2 !text-h3">
          Unraveling the Chardham Story
        </Heading>

        <Paragraph>
          The Chardham Yatra isn't just a tourist activity; it is closely
          stitched with legends, mythology, and a rich historical legacy. Each
          Dham echoes with stories passed down through generations, whispering
          tales of divine origins and celestial blessings. Keep reading to
          understand the origin story, spiritual significance, scenic beauty,
          beliefs, and legends revolving around each of the 4 dhams in
          Uttarakhand. Also, you can go through our <a href="/blogs">blogs</a>{" "}
          revolving around travel guides, experiences, and all things essential
          about the <a href="/chardham-by-helicopter">Chardham by Helicopter</a>
          .
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/chardham-new.jpeg"
            alt="Chardham"
            placeholder="blurred"
            objectFit="cover"
            className="w-[400px] h-[300px] text-center"
          />
          <Paragraph>Chardham</Paragraph>
        </div>

        <Heading type="h3" className="mt-2 !text-h4">
          Yamunotri – Where Life-Giving Waters Begin
        </Heading>
        <Paragraph>
          Legend traces the discovery of Yamunotri to Adi Shankara, the
          8th-century philosopher-saint who established the temple dedicated to
          Goddess Yamuna. The thermal springs here are believed to possess
          medicinal properties, attracting pilgrims for centuries.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/yamunotri-new.png"
            alt="Yamunotri"
            placeholder="blurred"
            objectFit="cover"
            className="w-[500px] h-[300px] text-center"
          />
          <Paragraph>Yamunotri</Paragraph>
        </div>
        <Heading type="h4" className="!text-tertiary !text-md">
          1. Home to the Divine Feminine:
        </Heading>
        <Paragraph>
          Yamunotri marks the source of the holy Yamuna River, representing a
          beautiful goddess, the sister of Yama, the god of death. She
          represents purity, life-giving force, and motherly compassion.
          Devotees believe taking a dip in her icy waters washes away sins and
          grants blessings for prosperity and happiness
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          2. A Gateway to Moksha:
        </Heading>
        <Paragraph>
          The River Yamuna is considered a tributary of the sacred Ganges,
          leading seekers on the path to moksha, liberation from the cycle of
          rebirth. Bathing in Yamunotri's waters and offering prayers to the
          goddess are seen as stepping stones towards spiritual liberation.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          3. Echoing Legends and History:
        </Heading>
        <Paragraph>
          Legend traces the discovery of Yamunotri to Adi Shankara, a revered
          8th-century philosopher-saint. He established the temple dedicated to
          Goddess Yamuna, further etching its significance in spiritual circles.
          The thermal springs here, mentioned in ancient scriptures, add another
          layer of historical and mythical weight.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          4. Healing Powers and Blessings:
        </Heading>
        <Paragraph>
          The thermal springs at Yamunotri are believed to possess medicinal
          properties. Pilgrims and locals alike bathe in their warm waters,
          seeking relief from disease and aches. Offering prayers and performing
          pujas at the temple are seen as ways of receiving blessings from the
          divine grace. Visit Yamunotri with packages to{" "}
          <a href="/chardham-by-helicopter">Chardham by helicopter</a> or{" "}
          <a href="/chardham-by-helicopter/packages/do-dham">
            Do Dham by Helicopter
          </a>{" "}
          <a href="/blogs">blogs</a>.
        </Paragraph>
        <Heading type="h3" className="mt-2 !text-h4">
          Gangotri – Where the Ganges Emerges, Cleansing the Soul
        </Heading>
        <Paragraph>
          At the source of the mighty Ganges, descending from the Gaumukh
          Glacier stands Gangotri. Here, you stand at the fountainhead of
          spiritual wisdom, liberation and positive vibes. The beauty of the
          surroundings, the gushing waters, and the ancient temple resonate with
          a power that washes away worldly worries.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/gangotri-new.png"
            alt="Gangotri"
            placeholder="blurred"
            objectFit="cover"
            className="w-[500px] h-[300px] text-center"
          />
          <Paragraph>Gangotri</Paragraph>
        </div>
        <Heading type="h4" className="!text-tertiary !text-md">
          1. The Cradle of the Divine River:
        </Heading>
        <Paragraph>
          Hindus believe the Ganges is not just a river, but a form of Goddess
          Ganga, the daughter of Lord Shiva. Gangotri marks the source of this
          sacred river, where she descended to cleanse the earth of sin.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          2. A Gateway to Enlightenment:
        </Heading>
        <Paragraph>
          The Ganges is considered a lifeline for India, nourishing the land and
          fostering spiritual growth. Bathing in its source at Gangotri is
          believed to be a potent step towards attaining moksha, liberation from
          the cycle of birth and death
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          3. Origin:
        </Heading>
        <Paragraph>
          The epic Mahabharata mentions the descent of Ganga, and the temple
          dedicated to the goddess is believed to have been built by Bhagiratha,
          a mythical king who brought the river down from the heavens. This
          ancient history adds to the mystical aura of the place.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          4. A Land of Healing and Blessings:
        </Heading>
        <Paragraph>
          The Gaumukh Glacier, where the Ganges emerge, is considered a sacred
          site in itself. Devotees believe that its icy waters possess healing
          powers. Offering prayers and performing rituals at Gangotri are seen
          as ways to seek blessings for good health, prosperity, and overall
          well-being.
        </Paragraph>
        <Paragraph>
          Visit Gangotri with packages to{" "}
          <a href="/chardham-by-helicopter">Chardham by helicopter</a> or{" "}
          <a href="/chardham-by-helicopter/packages/do-dham">
            Do Dham by Helicopter
          </a>{" "}
          or read more in our <a href="/blogs">blogs</a>.
        </Paragraph>
        <Heading type="h3" className="mt-2 !text-h4">
          Kedarnath – Unveiling the Power of Transformation
        </Heading>
        <Paragraph>
          Resting on the cliff of a Himalayan shoulder, Kedarnath, the abode of
          Lord Shiva, embodies the power of transformation. The challenging
          journey to reach this mystical Dham tests your physical and spiritual
          resolve, rewarding you with the ultimate darshan of the Destroyer and
          Redeemer.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/ekdham-new.jpeg"
            alt="Kedarnath"
            placeholder="blurred"
            objectFit="cover"
            className="w-[300px] h-[300px] text-center"
          />
          <Paragraph>Kedarnath</Paragraph>
        </div>
        <Heading type="h4" className="!text-tertiary !text-md">
          1. Abode of Lord Shiva:
        </Heading>
        <Paragraph>
          Kedarnath is revered as the holiest of twelve Jyotirlingas, physical
          representations of Lord Shiva scattered across India. Here, Shiva
          resides in the form of a naturally formed lingam, a symbol of his
          generative and transformative power. Pilgrims believe visiting
          Kedarnath offers profound blessings, spiritual liberation, and even
          moksha, release from the cycle of rebirth.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          2. Origin:
        </Heading>
        <Paragraph>
          According to the Mahabharata, the Pandavas sought forgiveness from
          Lord Shiva after the Kurukshetra war. He eluded them in various forms,
          finally disappearing into the ground at Kedarnath, leaving behind just
          his hump. The temple is believed to be built on the spot where this
          occurred, further solidifying its sacredness.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          3. Breathtaking Beauty:
        </Heading>
        <Paragraph>
          Kedarnath's location adds to its significance. Nestled amidst
          snow-covered peaks, surrounded by glacial valleys and gushing rivers,
          the very landscape evokes a spiritual sense among pilgrims. This
          natural beauty amplifies the spiritual experience, immersing pilgrims
          in the power and majesty of the Himalayas and Lord Shiva himself.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          4. A Symbol of Transformation:
        </Heading>
        <Paragraph>
          Lord Shiva, at Kedarnath, represents not just destruction but also
          transformation. Devotees believe visiting Kedarnath signifies letting
          go of negative aspects of oneself, embracing change, and ultimately
          achieving spiritual growth.
        </Paragraph>
        <Paragraph>
          Visit Kedarnath with packages to{" "}
          <a href="/chardham-by-helicopter">Chardham by helicopter</a> or{" "}
          <a href="/chardham-by-helicopter/packages/do-dham">
            Do Dham by Helicopter
          </a>{" "}
          or read more in our <a href="/blogs">blogs</a>.
        </Paragraph>
        <Heading type="h3" className="mt-2 !text-h4">
          Badrinath – Seeking Moksha at the Abode of Vishnu
        </Heading>
        <Paragraph>
          Amidst snow-laden peaks, Badrinath, the abode of Lord Vishnu,
          represents the culmination of the divine journey. Here, you seek
          blessings for moksha, liberation from the cycle of birth and death, in
          the presence of the Preserver of the Universe.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/badrinath-new.png"
            alt="Badrinath"
            placeholder="blurred"
            objectFit="cover"
            className="w-[300px] h-[300px] text-center"
          />
          <Paragraph>Badrinath</Paragraph>
        </div>
        <Heading type="h4" className="!text-tertiary !text-md">
          1. The Home of Lord Vishnu:
        </Heading>
        <Paragraph>
          Badrinath is one of the four most sacred pilgrimage sites in India,
          called the Char Dhams. Here, Lord Vishnu resides in a black stone
          statue that radiates peace and kindness. People believe a visit to
          Badrinath brings good luck, guides them towards happiness, and helps
          them break free from the cycle of birth and death.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          2. Whispers of Ancient Tales:
        </Heading>
        <Paragraph>
          Legends tell of Lord Vishnu meditating for thousands of years under a
          special Badri tree (hence the name Badrinath) to restore balance to
          the universe. The temple itself was built by a wise philosopher-saint
          named Adi Shankara, making it even more sacred.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          3. A Sanctuary of Peace:
        </Heading>
        <Paragraph>
          Badrinath is surrounded by snow-capped peaks, rushing rivers, and lush
          meadows, creating an atmosphere of introspection. This peace helps
          people quiet their minds, connect with nature's beauty, and deepen
          their devotion to Lord Vishnu.
        </Paragraph>
        <Heading type="h4" className="!text-tertiary !text-md">
          4. A Symbol of Hope and Refuge:
        </Heading>
        <Paragraph>
          Lord Vishnu represents the power that sustains and protects the
          universe. Spiritual belief states that visiting Badrinath offers a
          refuge from the ups and downs of life and reminds them that there's
          always hope and freedom, even beyond death.
        </Paragraph>
        <Paragraph>
          Visit Badrinath with packages to{" "}
          <a href="/chardham-by-helicopter">Chardham by helicopter</a> or{" "}
          <a href="/chardham-by-helicopter/packages/do-dham">
            Do Dham by Helicopter
          </a>{" "}
          or read more in our <a href="/blogs">blogs</a>.
        </Paragraph>
        <Paragraph>
          Note: Services to the{" "}
          <a href="/chardham-by-helicopter">Chardham Yatra by helicopter</a>
          are seasonal, and operational between Sep 15, 2024, to Oct 20, 2024.
          We are an aviation operator ourselves and take full accountability for
          the services and your{" "}
          <a href="/chardham-by-helicopter/#testimonials">experience</a>, unlike
          3rd party travel agencies which source their services from elsewhere.
          Book with us now to take the full advantage of the spiritual
          experience.
        </Paragraph>
        <Heading type="h2" className="mt-2 !text-h3">
          Unveiling Hidden Gems and Immersing in Culture
        </Heading>
        <Paragraph>
          Go beyond the iconic Dhams and discover hidden gems like Vasuki Tal, a
          serene lake nestled amidst breathtaking scenery, or Mana Village, the
          last Indian habitation before Tibet. Immerse yourself in local
          cultural experiences, like witnessing the Maha Arti at Kedarnath,
          watching the sun rise over the Yamuna River, or listening to the
          melodious hymns of Har Ki Pauri. These enriching experiences add depth
          and dimension to your pilgrimage, creating memories that transcend the
          ordinary.
        </Paragraph>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/manavillage-new.png"
            alt="Mana Village"
            placeholder="blurred"
            objectFit="cover"
            className="w-[400px] h-[300px] text-center"
          />
          <Paragraph>Mana Village</Paragraph>
        </div>
        <Heading type="h2" className="mt-2 !text-h3">
          Beyond the Helicopter Blades: Deepen Your Spiritual Quest
        </Heading>
        <Paragraph>
          Chardham Yatra by Helicopter ensures you receive the full blessings of
          each Dham with personalized pujas guided by experienced pandits. These
          are tailored to your spiritual aspirations, whether it's a private
          abhishekam at Kedarnath or a serene meditation session amidst the
          majestic peaks of Gangotri.
        </Paragraph>
        <Paragraph>
          Check out our add-on services in each{" "}
          <a href="/chardham-by-helicopter/packages">package</a> to fully
          understand the experience.
        </Paragraph>
        <Heading type="h2" className="mt-2 !text-h3">
          Testimonials and Inspiration: Let Others Guide Your Journey
        </Heading>
        <Paragraph>
          Don't just take our word for it. Visit our{" "}
          <a href="/chardham-by-helicopter/#testimonials">testimonials</a> page
          and hear how Chardham by Helicopter has transformed the pilgrimage for
          countless individuals like you. Read their stories of spiritual
          awakening, awe-inspiring sights, and the unparalleled comfort we
          provide.
        </Paragraph>
        <section>
          <Testimonials />
        </section>
        <Heading type="h2" className="mt-2 !text-h3">
          Stay Connected:{" "}
          <a
            href="https://www.instagram.com/helitaxii/"
            className="underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            Social Media
          </a>
        </Heading>
        <Paragraph>
          Keep the spirit of Chardham alive by following us on social media.
          Witness the breathtaking beauty of the Himalayas, discover hidden
          gems, and connect with fellow pilgrims on Facebook, Instagram, and
          Twitter. Share your experiences, ask questions, and let the divine
          energy of the Himalayas flow through your social feeds.
        </Paragraph>
        <Heading type="h2" className="mt-2 !text-h3">
          Let Us Compose Your Chardham Journey:{" "}
          <a href="/chardham-by-helicopter" className="underline">
            Book Now
          </a>
        </Heading>
        <Paragraph>
          Chardham by Helicopter is more than just a travel package; it's an
          invitation to redefine your pilgrimage experience. It's about soaring
          above the ordinary, immersing yourself in the divine, and returning
          home with memories that will stay etched in your soul. Overall, the
          Chardham Yatra by helicopter offers convenience, luxury, and a
          time-efficient way to experience the spiritual bliss of these sacred
          sites. With its reduced travel time and comfortable transportation
          from Dehradun, devotees can immerse themselves in the divine
          atmosphere of the Chardham.
        </Paragraph>
        <Paragraph className="mt-4">
          For more on Chardham Yatra by helicopter, visit our{" "}
          <a href="/chardham-by-helicopter/packages">packages</a>,{" "}
          <a href="/chardham-by-helicopter/">booking page</a>,{" "}
          <a href="?show=contact-us">contact us</a>, read{" "}
          <a href="/blogs">blogs</a>, and{" "}
          <a href="/chardham-by-helicopter/#testimonials">testimonials</a>, or
          visit our{" "}
          <a
            href="https://www.instagram.com/helitaxii/"
            className="underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            social Media
          </a>{" "}
          pages to learn more.
        </Paragraph>
        <Paragraph>
          Thank you for reading! Wishing all the pilgrims a very happy, fruitful
          and safe Chardham Yatra.
        </Paragraph>
      </div>
    </Layout>
  );
};

export default BlogDetails;
